<template>
  <el-dialog v-model="visible" title="批准文件" :append-to-body="true">
    <el-card v-loading="isLoading">
      <div class="borderForm">
        <el-form :model="form" label-width="200px" ref="refForm" :rules="rules">
          <el-form-item label="发文机构" prop="doc_Org">
            <el-radio-group v-model="form.doc_Org">
              <el-radio-button :label="item.value" v-for="item in docOrgDic" :key="item.value">{{ item.label
                }}</el-radio-button>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="批文类型" class="is-required">
            <!-- <template #label>
              <div class="is-required">批文类型</div>
            </template> -->
            <el-space style="vertical-align: top">
              <el-form-item prop="doc_Type">
                <el-select v-model="form.doc_Type" placeholder="批文类型">
                  <el-option :label="item.label" :value="parseInt(item.value)" v-for="item in docTypeDic"
                    :key="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item prop="doc_Year">
                <el-select v-model="form.doc_Year" placeholder="批文年份">
                  <el-option :label="item" :value="item" v-for="item in docYearDic" :key="item"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item prop="doc_Serial_No">
                <el-input v-model="form.doc_Serial_No" placeholder="批文号"></el-input>
              </el-form-item>
              <span>号</span>
            </el-space>
          </el-form-item>
          <el-form-item label="文号重用" prop="isMuliNo">
            <el-space>
              <el-switch v-model="form.isMuliNo"></el-switch>
              <el-input v-show="form.isMuliNo" v-model="form.muliNoKey" placeholder="子标识"></el-input>
            </el-space>
          </el-form-item>
          <el-form-item label="批文标题" prop="doc_Title">
            <el-input v-model="form.doc_Title" placeholder="批文标题"></el-input>
          </el-form-item>
          <el-form-item label="批复类型" prop="doc_Category">
            <el-radio-group v-model="form.doc_Category">
              <el-radio-button :label="item.value" v-for="item in docCategoryDic.filter(
    (p) => p.extends == null || !p.extends.disabled
  )" :key="item.value">{{ item.label }}</el-radio-button>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="附件" prop="filePath">
            <fileupload :setting="fileuploadSetting" @remove="(res) => onRemoveAttach(res)"
              @upload="(res) => onUploadAttach(res)" @progress="() => (isFileUploading = true)"></fileupload>
          </el-form-item>
        </el-form>
      </div>
    </el-card>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="visible = false">关闭</el-button>
        <el-popconfirm title="确认提交吗！？" @confirm="doSave" placement="top">
          <template #reference>
            <el-button type="primary" :loading="isLoading || isFileUploading">
              {{
    isFileUploading ? "上传中..." : isLoading ? "处理中" : "提交"
              }}
            </el-button>
          </template>
        </el-popconfirm>
      </span>
    </template>
  </el-dialog>
</template>

<script lang="ts">
import fileupload from "@/components/fileupload";
import { ElMessage } from "element-plus";
import { rules } from "@/model/officialDocFormRules.ts";
import { useOfficialDoc } from "@/network/officialDoc.ts";
import { useSelectItemList, docOrgDic } from "@/network/lookUp.ts";
import {
  defineComponent,
  toRefs,
  ref,
  reactive,
  watch,
  Ref,
  PropType,
  computed,
  nextTick,
  onMounted
} from "vue";
import settings from "@/settings";
export default defineComponent({
  components: {
    fileupload,
  },
  props: {
    setting: { type: Object },
  },
  setup(props, { emit }) {
    // const form = reactive({
    //   reduces: [],
    //   attachment: null,
    // });;

    const fileuploadSetting = reactive({
      limit: 1,
      accpet: "application/pdf",
      fileList: [],
    });

    const refForm = ref(null);
    const isFileUploading = ref(false);
    const { visible, id } = toRefs(props.setting as any);
    const [isLoading, form, find, save, submit] = useOfficialDoc(id);
    const now = new Date();

    const docYearDic = ref([]);
    for (let i = 0; i < 10; i++) {
      docYearDic.value.push((now.getFullYear() - i).toString());
    }
    const docTypeDic = useSelectItemList("OfficialDocType");
    const docCategoryDic = useSelectItemList("OfficialDocCategory");
    // const docOrgDic = ref([{labe:"",}]);

    // watch(visible, (v) => {
    //   if (v) {
    //     find().then((p) => {
    //       nextTick(() => {
    //         setTimeout(() => refForm.value.clearValidate(), 1);
    //       })

    //       if (form.filePath) {
    //         fileuploadSetting.fileList = [
    //           {
    //             url: form.filePath,
    //             name: form.fileName,
    //           },
    //         ];
    //       }
    //     });
    //   } else {
    //     fileuploadSetting.fileList = [];
    //   }
    // });
    onMounted(() => {
      find().then((p) => {
        nextTick(() => {
          setTimeout(() => refForm.value.clearValidate(), 1);
        })

        if (form.filePath) {
          fileuploadSetting.fileList = [
            {
              url: form.filePath,
              name: form.fileName,
            },
          ];
        }
      });
    })

    const onRemoveAttach = (res) => {
      form.filePath = null;
      form.fileName = null;
    };
    const onUploadAttach = ({ url, name }) => {
      form.filePath = url;
      form.fileName = name;
      isFileUploading.value = false;
    };

    const doSave = (changeEmit = true) => {
      return new Promise((resolve, reject) => {
        refForm.value.validate((valid) => {
          if (valid) {
            form.doc_Compile_No = `${docTypeDic.value.find((p) => p.value == form.doc_Type).label
              }﹝${form.doc_Year}﹞${form.doc_Serial_No}号`;
            form.Approved = 1;
            save().then((id) => {
              resolve("success");
              visible.value = false;

              if (changeEmit) emit("change", id);
            });
          } else {
            reject("error");
          }
        });
      });
    };

    return {
      docOrgDic,
      docCategoryDic,
      docYearDic,
      docTypeDic,
      fileuploadSetting,
      onRemoveAttach,
      onUploadAttach,
      refForm,
      isLoading,
      action: settings.uploadAction,
      visible,
      form,
      doSave,
      rules,
      isFileUploading,
    };
  },
});
</script>

<style scoped></style>