import { Ref, watch, ref, onMounted } from "vue";
import { isNumber, isEmpty } from "@/utils/validate";

// const validateArr = (rule, value, callback) => {
//   if (value === "") {
//     callback(new Error("请选择城市"));
//   } else {
//     // if (this.ruleForm.checkPass !== "") {
//     //   this.$refs.ruleForm.validateField("checkPass");
//     // }
//     callback();
//   }
// };

export const rules = {
  doc_Org: [{ required: true, message: "请选择发文机构！", trigger: "change" }],
  doc_Type: [
    { required: true, message: "请选择批文类型！", trigger: "change" },
  ],
  doc_Year: [
    { required: true, message: "请选择批文年份！", trigger: "change" },
  ],
  doc_Serial_No: [
    { required: true, message: "请输入批文号！", trigger: "blur" },
    {
      message: "填报不正确，请填写数字",
      trigger: "blur",
      validator: (rule, value, callback) => {
        if (isEmpty(value) || !isNumber(value)) {
          return callback(Error(rule.message));
        }
        callback();
      },
    },
  ],
  doc_Title: [{ required: true, message: "请输入批文标题！", trigger: "blur" }],
  doc_Category: [
    { required: true, message: "请选择批复类型！", trigger: "blur" },
  ],
  filePath: [{ required: true, message: "请上传批复文件！", trigger: "blur" }],
};
